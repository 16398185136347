<template>
  <section class="page-container">
    <el-row
      align="center"
      :gutter="40"
      class="row-bg margin-sm-and-down"
    >
      <v-row-col
        v-for="(list, index) in list"
        :key="index"
        :xs="11"
        :sm="11"
        :md="8"
        :lg="8"
        :xl="8"

      >
        <div
          class="type-box card"
          align="center"
          :class="{'is-disable':list.disable}"
          @click="typeClick(list)"
        >
          <h2 class="type-label theme-blue">{{ list.label }}</h2>
        </div>
      </v-row-col>
    </el-row>
    <el-row
      class="hidden-sm-and-up"
      justify="space-around">

    </el-row>
  </section>
</template>
<script>
import {mapActions} from 'vuex'

export default {
  name: 'createQuoteHome',
  data() {
    return {
      list: [
        {
          // img: require('@/assets/images/quote/banner-motor.png'),
          productId: 10,
          label: 'Motor Cycle',
          typeList: ['Personal', 'Company'],
          path:'/quotation/create/motor',
          classId: 1003
        },
        {
          // img: require('@/assets/images/quote/banner-motor.png'),
          productId: 10,
          label: 'Private Car',
          typeList: ['Personal', 'Company'],
          path:'/quotation/create/motor',
          classId:1001,
          disable:true
        },
        {
          // img: require('@/assets/images/quote/banner-motor.png'),
          productId: 10,
          label: 'Commercial Car',
          typeList: ['Personal', 'Company'],
          path:'/quotation/create/motor',
          classId: 1002,
          disable:true
        },
        // {
        //   // img: require('@/assets/images/quote/banner-housemaid.png'),
        //   productId: 11,
        //   label: 'Contractors\' All Risks',
        //   path: '/quotation/create/contractors'
        // },
        {
          // img: require('@/assets/images/quote/banner-working.png'),
          productId: 12,
          label: 'Travel',
          path:'/quotation/create/travel',
          disable:true
        },
        //
        //
        {
          // img: require('@/assets/images/quote/banner-travel.png'),
          productId: 13,
          label: 'Home',
          path:'/quotation/create/home',
          disable:true
        },
        // {
        //   // img: require('@/assets/images/quote/banner-interior.png'),
        //   label: 'Employees\' Compensation',
        //   productId: 14,
        //   path:'/quotation/create/employee'
        // },
        // {
        //   // img: require('@/assets/images/quote/banner-overseas-student.png'),
        //   label: 'Business Package',
        //   productId: 15,
        //   path:'/quotation/create/business'
        // },
        // {
        //   // img: require('@/assets/images/quote/banner-home.png'),
        //   label: 'Domestic Helper',
        //   productId: 16,
        //   path:'/quotation/create/domestic'
        // },
        // {
        //   // img: require('@/assets/images/quote/banner-personal-accident.png'),
        //   label: 'Construction Employee Compensation',
        //   productId: 17,
        //   path:  '/quotation/create/construction'
        // },
        {
          // img: require('@/assets/images/quote/banner-medical.png'),
          label: 'Others',
          productId: 18,
          path:  '/quotation/create/other',
          disable:true
        },
      ],

    }
  },
  created() {
    this.setQuoteInformationForm(null)
  },
  methods: {
    ...mapActions('quotation', ['setQuoteInformationForm','setNcd','setProductID']),
    ...mapActions('travel', ['setTravelNo']),
    ...mapActions('common', ['setBlacklistStatus']),
    ...mapActions('coverage', ['setDomesticPremium','setHomePremium','setPremium','setConstructionPremium']),

    typeClick({disable,productId,path,classId}) {
      if(disable)return
      this.setQuoteInformationForm(null)
      this.setConstructionPremium(null)
      this.setDomesticPremium(null)
      this.setHomePremium(null)
      this.setPremium(null)
      this.setBlacklistStatus({blacklist:false})
      this.setTravelNo(1)
      this.setNcd(0)
      this.setProductID(productId)
      if(path)
        this.$router.push({
          path:path,
          query:{
            class_id:classId
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  color: $primary;
  width: 100%;
  height: 100%;

  h2 {
    font-size: 24px;
  }
  .row-bg {
    //padding: 10px;
    .el-col {

      margin-bottom: 40px;
      height: 150px;
      //margin-right: 40px;
      //margin-bottom: 40px;

      .card{
        width: 100%;
        height: 100%;
        display: flex;

        justify-content: center;
        align-items: center;
        cursor:pointer;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
        &.is-disable{
          background-color: $primary-disable;
        }
      }
    }
  }
}
</style>
