import { render, staticRenderFns } from "./createQuoteHome.vue?vue&type=template&id=354ccfa4&scoped=true&"
import script from "./createQuoteHome.vue?vue&type=script&lang=js&"
export * from "./createQuoteHome.vue?vue&type=script&lang=js&"
import style0 from "./createQuoteHome.vue?vue&type=style&index=0&id=354ccfa4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "354ccfa4",
  null
  
)

export default component.exports